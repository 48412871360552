import get from 'lodash/get';
// False alarm due to unusual project structure containing app in src
// eslint-disable-next-line import/no-unresolved
import stringList from 'locales/stringList.json';

// Copied from f1-loki-storefront
// Based from https://stackoverflow.com/questions/2534803/use-of-string-format-in-javascript
export function getLang(stringKey, params = {}) {
  const res = get(stringList, stringKey, stringKey);
  return res.replace(/{{\w+}}/g, (placeholderWithDelimiters) => {
    const placeholderWithoutDelimiters = placeholderWithDelimiters.substring(
      2,
      placeholderWithDelimiters.length - 2,
    );
    return get(params, placeholderWithoutDelimiters, placeholderWithDelimiters);
  });
}
